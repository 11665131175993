import { useState, useCallback, useEffect, useMemo } from 'react'
import fromExponential from 'from-exponential'

import { ZERO_ADDRESS, LICENSEE } from '../constants/addresses'
import { defaultToken } from '../constants/index'
import uniswapAbi from '../abi/uniswap.json'
import factoryAbi from '../abi/factory.json'
import ERC20 from '../abi/ERC20.json'
import { toBig, _toFixed } from '../services/data_service'
import { getDecimal } from '../utils/new'
import { isValidNumber } from '../utils/validator'
import { smartPath } from '../utils/new'
import { useWeb3 } from 'hooks'
import swapAbi from '../abi/swap.json'
import Web3Helpers from '../utils/web3Helpers'
import { useAppSnackbar } from './useAppSnackbar'

export const SwapErrorMessages = {
  4001: 'Transaction Rejected',
  32000: 'Transaction failed, please try with a larger amount.',
}

export const SelectType = {
  TokenA: 1,
  TokenB: 2,
}

/**
 *
 * @param {WrapContractAddress} W_TOKEN_ADDRESS
 * @param {IAgentAddress} IAgentAddress @description IAgentAddress is required for func swap
 * @param {RouterAdress} router
 * @param {FactoryAdress} factoryAddress
 * @param {Account} account
 * @param {Number} transactionDeadline @description Minutes in Number
 */
export const useSwap = ({
  W_TOKEN_ADDRESS,
  IAgentAddress,
  router,
  factoryAddress,
  account,
  options: { defaultTokenA = defaultToken, defaultTokenB = defaultToken, slippageValue = 0, transactionDeadline = 1 },
}) => {
  const [openSnackbar] = useAppSnackbar()
  const web3 = useWeb3()
  const [tokenA, _setTokenA] = useState(defaultTokenA) // from token
  const [tokenB, _setTokenB] = useState(defaultTokenB) // to token
  const [amountA, setAmountA] = useState(0) // from input amount
  const [amountB, setAmountB] = useState(0) // to input amount
  const [balanceA, setBalanceA] = useState(0)
  const [priceImpact, setPriceImpact] = useState(0) // priceImpact
  const [approved, setApproved] = useState(false) //
  const [pairAddress, setPairAddress] = useState(ZERO_ADDRESS) //
  const [isRefreshingAmountOut, setIsRefreshingAmountOut] = useState(false) //

  const factories = useMemo(() => {
    return new web3.eth.Contract(factoryAbi, factoryAddress)
    // disabled for web3
    // eslint-disable-next-line
  }, [factoryAddress])

  /**
   *
   * @param {address} tokenA
   * @param {address} tokenB
   * @param {Number} amount
   * @param {address} walletAddress
   * @param {Boolean} bool @description true for amountIn
   * @returns
   */
  const getAmountOut = useCallback(
    async (tokenA, tokenB, amount, bool = false) => {
      let [tokenA_Address, tokenB_Address] = smartPath(W_TOKEN_ADDRESS, [tokenA, tokenB])
      let path = []
      if (!pairAddress || pairAddress === ZERO_ADDRESS) {
        // for non-pair tokens
        path = [tokenA_Address, W_TOKEN_ADDRESS, tokenB_Address]
      } else {
        path = [tokenA_Address, tokenB_Address]
      }

      const swaping = new web3.eth.Contract(uniswapAbi, router)
      const web3Helpers = new Web3Helpers(web3)

      const contractA = new web3.eth.Contract(ERC20, tokenA_Address)
      const contractB = new web3.eth.Contract(ERC20, tokenB_Address)
      const decimalA = await contractA.methods.decimals().call()
      const decimalB = await contractB.methods.decimals().call()

      if (bool) {
        // getAmountIn
        let amountOut = toBig(fromExponential(amount * Math.pow(10, decimalB)))
        let impAmount = fromExponential(amount * Math.pow(10, decimalB))
        const res = await swaping.methods.getAmountsIn(amountOut, path).call()
        let amountIn = fromExponential(parseFloat(res[0]) / 10 ** decimalA)
        const impactValue = await web3Helpers.getImpact(tokenA_Address, tokenB_Address, res[0], impAmount, router, W_TOKEN_ADDRESS)
        return {
          priceImpact: impactValue,
          value: amountIn,
        }
      } else {
        // getAmountOut
        let amountIn = toBig(fromExponential(amount * Math.pow(10, decimalA)))
        let impAmount = fromExponential(amount * Math.pow(10, decimalA))
        let res = await swaping.methods.getAmountsOut(amountIn, path).call()
        let amountOut = fromExponential(res[res.length - 1] / 10 ** decimalB)
        const impactValue = await web3Helpers.getImpact(tokenA_Address, tokenB_Address, impAmount, res[res.length - 1], router, W_TOKEN_ADDRESS)

        return {
          priceImpact: impactValue,
          value: amountOut,
        }
      }
    },
    // disabled for web3 and factories contract
    // eslint-disable-next-line
    [W_TOKEN_ADDRESS, pairAddress, router]
  )

  /**
   *
   * @param {address} tokenAddress
   * @param {NumberWithDecimal} amount
   */
  const approve = useCallback(async () => {
    const ercContract = await new web3.eth.Contract(ERC20, tokenA.address)
    const tokenADecimal = await getDecimal(web3.eth, tokenA.address)
    let amount = amountA || 100
    try {
      const res = await ercContract.methods.approve(router, parseInt(amount * Math.pow(10, tokenADecimal))).send({ from: account })
      setApproved(true)
      return true
    } catch (err) {
      console.error(err)
      setApproved(false)
      openSnackbar(err)
      return false
    }
  }, [account, amountA, router, openSnackbar, tokenA.address, web3.eth])

  // get amount out
  const refreshOutAmount = useCallback(
    async (tokenA, tokenB, amount, type) => {
      let setFuncRef = () => {}
      if (type === SelectType.TokenA) {
        setFuncRef = setAmountB
      } else {
        setFuncRef = setAmountA
      }
      if (tokenA && tokenB && amount > 0) {
        try {
          const result = await getAmountOut(tokenA, tokenB, amount, type === SelectType.TokenB)
          setPriceImpact(result.priceImpact)
          setFuncRef(_toFixed(result.value, 6))
        } catch (err) {
          console.error(err)
          setAmountA(0)
          setAmountB(0)
        }
      } else {
        setFuncRef(0)
      }
    },
    [getAmountOut]
  )

  const handleChangeAmountA = useCallback(
    async (value) => {
      if (isValidNumber(value)) {
        setAmountA(value)
        try {
          setIsRefreshingAmountOut(true)
          await refreshOutAmount(tokenA.address, tokenB.address, Number(value), SelectType.TokenA)
        } catch (err) {
          console.error(err)
        } finally {
          setIsRefreshingAmountOut(false)
        }
      }
    },
    [tokenA.address, tokenB.address, refreshOutAmount]
  )

  const swap = useCallback(async () => {
    if (tokenA.address === '') return openSnackbar('Please select the token pair to swap')
    if (tokenB.address === '') return openSnackbar('Please select the token pair to swap')
    if (amountA < 0) return openSnackbar('Please enter a token amount to swap')

    let [tokenAAddress, tokenBAddress] = [tokenA.address, tokenB.address]
    const web3Helpers = new Web3Helpers(web3)

    const tokenADecimal = await web3Helpers.getDecimal(tokenAAddress)
    const tokenBDecimal = await web3Helpers.getDecimal(tokenBAddress)
    //.........................APRROVE MAX AMOUNT OF WALLLET.................................
    let swaping = new web3.eth.Contract(swapAbi, IAgentAddress)
    let licensee = LICENSEE
    let amountIn = toBig(fromExponential(Math.round(amountA * Math.pow(10, tokenADecimal))))
    let amountOut = 0
    if (slippageValue) {
      let _amountOut = (amountB - (amountB * slippageValue) / 100) * Math.pow(10, tokenBDecimal)
      _amountOut = Math.round(_amountOut)
      amountOut = toBig(fromExponential(_amountOut))
    } else {
      // not slippage
      if (tokenA.name === 'UNI' && tokenB.name === 'DAI') {
        amountOut = toBig(Math.round(fromExponential(amountB * Math.pow(10, tokenBDecimal / 1000)))) // // TODO need to check 1000 this
      } else {
        amountOut = toBig(Math.round(fromExponential(amountB * Math.pow(10, tokenADecimal))))
      }
    }

    let swapType = 0
    let path = [tokenAAddress, tokenBAddress]
    const fees = await swaping.methods.getFee(tokenA.address, amountIn, licensee, router).call({ from: account })

    if (tokenA.address === ZERO_ADDRESS) {
      let calculate = parseFloat(amountA) * Math.pow(10, tokenADecimal)

      let amount = toBig(fromExponential(calculate + parseInt(fees)))

      let deadline = parseInt(Date.now() / 1000) + transactionDeadline * 60
      // TODO swapType is 0 need to check this value
      const res = await swaping.methods.swap(licensee, amountIn, amountOut, path, deadline, swapType, router).send({ from: account, value: amount })
      let transactionHash = res.transactionHash
      localStorage.setItem('transactionHash', transactionHash)
      handleChangeAmountA(0)
    } else {
      const ercContract = new web3.eth.Contract(ERC20, tokenAAddress)
      if (!pairAddress || pairAddress === ZERO_ADDRESS) {
        path = [tokenAAddress, W_TOKEN_ADDRESS, tokenBAddress]
      } else {
        path = [tokenAAddress, tokenBAddress]
      }

      const allowance = await ercContract.methods.allowance(account, router).call({ from: account })
      //  // console.log(4)
      if (approved && allowance >= parseFloat(amountIn)) {
        // approved
        let deadline = parseInt(Date.now() / 1000) + transactionDeadline * 60
        try {
          let res = await swaping.methods.swap(licensee, amountIn, amountOut, path, deadline, swapType, router).send({
            from: account,
            value: fees,
          })
          let transactionHash = res.transactionHash
          localStorage.setItem('transactionHash', transactionHash)
          handleChangeAmountA(0)
        } catch (err) {
          openSnackbar(err.code === 4001 ? 'Transaction Rejected' : 'Transaction failed')
        }
      } else {
        console.log('Approve is required')
        approve()
      }
    }
  }, [
    IAgentAddress,
    W_TOKEN_ADDRESS,
    account,
    amountA,
    amountB,
    approve,
    approved,
    handleChangeAmountA,
    pairAddress,
    router,
    slippageValue,
    tokenA.address,
    tokenA.name,
    tokenB.address,
    tokenB.name,
    transactionDeadline,
    web3,
    openSnackbar,
  ])

  const handleChangeAmountB = useCallback(
    async (value) => {
      if (isValidNumber(value)) {
        setAmountB(value)
        try {
          setIsRefreshingAmountOut(true)
          await refreshOutAmount(tokenA.address, tokenB.address, Number(value), SelectType.TokenB)
        } catch (error) {
          console.error(error)
        } finally {
          setIsRefreshingAmountOut(false)
        }
      }
    },
    [tokenA.address, tokenB.address, refreshOutAmount]
  )

  const useMaxBalance = useCallback(() => {
    setAmountA(balanceA)
  }, [balanceA])

  const switchTokens = useCallback(() => {
    let temp = Object.assign(tokenA)
    _setTokenA(Object.assign(tokenB))
    _setTokenB(Object.assign(temp))
    setAmountA(amountB)
    refreshOutAmount(tokenB.address, tokenA.address, amountB, SelectType.TokenA)
  }, [tokenA, tokenB, amountB, refreshOutAmount])

  const setTokenA = useCallback(
    async (_token) => {
      let _newToken = {
        image: _token.image,
        name: _token.name,
        address: _token.address,
      }
      _setTokenA(_newToken)
      try {
        setIsRefreshingAmountOut(true)
        await refreshOutAmount(_token.address, tokenB.address, amountA, SelectType.TokenA)
      } catch (error) {
        console.error(error)
      } finally {
        setIsRefreshingAmountOut(false)
      }
    },
    [amountA, refreshOutAmount, tokenB.address]
  )

  const setTokenB = useCallback(
    async (_token) => {
      let _newToken = {
        image: _token.image,
        name: _token.name,
        address: _token.address,
      }
      _setTokenB(_newToken)
      try {
        setIsRefreshingAmountOut(true)
        await refreshOutAmount(tokenA.address, _token.address, amountA, SelectType.TokenA)
      } catch (error) {
        console.error(error)
      } finally {
        setIsRefreshingAmountOut(false)
      }
    },
    [tokenA.address, amountA, refreshOutAmount]
  )

  useEffect(() => {
    if (tokenA.address && tokenA.address === ZERO_ADDRESS) {
      setApproved(true)
    } else {
      setApproved(false)
    }
  }, [tokenA.address])

  useEffect(() => {
    const fetch = async () => {
      if (tokenA.address && web3 && account) {
        const web3Helpers = new Web3Helpers(web3)
        try {
          const _balance = await web3Helpers.getBalance(account, tokenA.address)
          setBalanceA(fromExponential(_balance))
        } catch (error) {
          console.error(error)
          setBalanceA(0)
        }
      } else {
        setBalanceA(0)
      }
    }
    fetch()
  }, [tokenA.address, web3, account])

  useEffect(() => {
    const fetchPairAddress = async () => {
      let [addressA, addressB] = smartPath(W_TOKEN_ADDRESS, [tokenA.address, tokenB.address])
      let _pairAddress = await factories.methods.getPair(addressA, addressB).call({ from: account })
      if (tokenA === ZERO_ADDRESS) {
        addressA = W_TOKEN_ADDRESS
      } else if (tokenB === ZERO_ADDRESS) {
        addressB = W_TOKEN_ADDRESS
      }
      setPairAddress(_pairAddress)
    }
    if (tokenA.address && tokenB.address) {
      fetchPairAddress()
    }
    // disabled for contract factories
    // eslint-disable-next-line
  }, [tokenA.address, tokenB.address, account, W_TOKEN_ADDRESS])

  return {
    tokenA,
    tokenB,
    amountA,
    amountB,
    balanceA,
    priceImpact,
    pairAddress,
    approved,
    isRefreshingAmountOut,
    swap,
    approve,
    handleChangeAmountA,
    handleChangeAmountB,
    useMaxBalance,
    setTokenA,
    setTokenB,
    switchTokens,
    refreshOutAmount,
  }
}

import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Gs from '../Theme/globalStyles'
import TokenPopup from '../Component/popup/tokenPopup'
import TransactionPopup from '../Component/popup/transactionPopup'

import { _toFixed } from '../services/data_service'
import TransactionSetting from '../Component/TransactionSetting'
import AllDex from '../Component/allDEX'
import AllBlockchains from '../Component/allBlockchains'
import { withSnackbar } from 'react-simple-snackbar'
import { ZERO_ADDRESS } from '../constants/addresses'
// import Loader from '../Component/loader'
import SwapComponent from '../Component/Swap/swap'
import LiquidityComponent from '../Component/Liquidity/index'
import Tabs from '../Component/shared/Tabs'
import AppToolTip from '../Component/AppToolTip'
import { usePageChain, useStorage } from '../hooks'

function SwapPage({ dex }) {
  // const [isLoading, setIsLoading] = useState(false)
  const [showTransaction, setShowTransaction] = useState(false)
  const [showTokenPopup, setShowTokenPopup] = useState(false)
  const [pairToken, setPairToken] = useState(false)
  const [tabIndex, setTabIndex] = useState(1)
  const chainName = usePageChain()
  const { loadTokens, initList } = useStorage(chainName)

  const onChangePairToken = useCallback((_pairToken) => {
    setPairToken(_pairToken)
  }, [])

  useEffect(() => {
    initList()
  }, [initList])

  useEffect(() => {
    const timer = setTimeout(() => {
      loadTokens()
    }, 200)

    return () => {
      clearTimeout(timer)
    }
  }, [loadTokens])

  return (
    <>
      <Gs.Container>
        {/* {isLoading ? <Loader /> : <></>} */}
        <TransactionPopup
          isOpen={showTransaction}
          dismiss={() => {
            setShowTransaction(false)
          }}
          chain={'TODO'}
        />
        {/* <Tvl dex='quickswap'/> */}
        <SwapMbox01>
          <SwapSbox01>
            <LiquidityComponent />
          </SwapSbox01>
          <SwapSbox02>
            <SwapTitle04>Choose your DEX</SwapTitle04>

            <SwapBTN02 className='border-highlight-shadow-primary' onClick={() => setShowTokenPopup(true)}>
              <img style={{ height: '75px' }} src={require(`../Assets/images/${dex.id}.png`).default} className='imgBox01' alt='Dex Icon' />
              <span>
                {dex.title} <i className='fas fa-caret-down'></i>
              </span>
            </SwapBTN02>

            <SwapTitle04 className='smlTitle'>
              <p>
                Gas and fees 100% reimbursement with DRX
                <i
                  className='fas helpIco fa-question-circle'
                  data-tip='DynamicSwap users have the option to receive 100% reimbursement of their gas and fees through the reimbursement 
                  staking contract. To take advantage of the reimbursement opportunity, users stake 1:1 DRX, 
                  the exact value of gas and fees incurred, for one year. 
                  At any time within the 1 year period, any amount of the staked funds can be withdrawn without penalty. 
                  At the end of the 1 year period, and any time after, the user can claim the remaining staked tokens.'
                ></i>
              </p>
            </SwapTitle04>
          </SwapSbox02>

          <SwapSbox01 className='rightAlign'>
            <Tabs activedTabIndex={tabIndex}>
              <div tabIndex={1}>
                <SwapComponent onChangePairToken={onChangePairToken} />
              </div>
              <div tabIndex={2}>
                <TransactionSetting onClickClose={() => setTabIndex(1)} />
              </div>
            </Tabs>

            <SwapLinkbx>
              {/* <a >Market order</a> |
                              <a >Limit order</a> | */}
              <a href={`${dex?.pairUrl}${pairToken}`} target='_blank' data-tip={pairToken === ZERO_ADDRESS ? 'Analytics not available for selected pair of tokens' : ''} rel='noreferrer'>
                Analysis
              </a>
              |<span onClick={() => setTabIndex(2)}>Settings</span>
            </SwapLinkbx>
          </SwapSbox01>
        </SwapMbox01>

        <div className='mt-50 relative z-index-10'>
          <AllDex />
          <AllBlockchains />
        </div>
      </Gs.Container>
      <TokenPopup isOpen={showTokenPopup} dismiss={() => setShowTokenPopup(false)} />
      <AppToolTip />
    </>
  )
}

SwapPage.propTypes = {
  dex: PropTypes.object.isRequired,
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const SwapMbox01 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 60px 0 0 0;
  padding: 10px;
`
const SwapSbox01 = styled(FlexDiv)`
  width: 33.33%;
  justify-content: flex-start;

  &.rightAlign {
    justify-content: flex-end;
  }
`

const SwapSbox02 = styled(FlexDiv)`
  width: 33.33%;
  padding: 70px 15px;
  flex-direction: column;
`

const SwapTitle04 = styled(FlexDiv)`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 20px;
  justify-content: flex-start;

  &.smlTitle {
    font: 300 11px/20px 'IBM Plex Mono', arial;
    color: #8e9195;
    margin-top: 20px;
    display: block;
    text-align: center;
    p {
      text-transform: none !important
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
`

const SwapBTN02 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  border: none;
  background-color: #8ae059;
  width: 152px;
  height: 152px;
  padding: 15px;
  border-radius: 80px;

  .imgBox01 {
    margin-bottom: 12px;
    width: 50%;
  }

  :hover {
    background-color: #70c142;
  }
`

const SwapLinkbx = styled(FlexDiv)`
  font-size: 12px;
  color: #ababab;
  font-weight: 400;
  min-height: 46px;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;

  a,
  span {
    color: #ababab;
    margin: 0 4px;
    cursor: pointer;

    :hover {
      color: #8ae059;
    }
  }
`

export default withSnackbar(SwapPage)

import InputRange from 'react-input-range'
import { _toFixed } from '../../services/data_service'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { ArrowDownIconWrapper, DragorInput, RLBTNBX, RLTitle01, SSbx02, SwapSSbox01, SwapTitle01, YLBtnBar, YLMBX, YLTitle02 } from '../styled'
import AppToolTip from '../AppToolTip'
import fromExponential from 'from-exponential'
import { CloseBtn, TokenIcon } from '../shared/common'

function RemoveLiquidity({
  tokenA,
  tokenB,
  toPrice,
  balanceA,
  fromAmount,
  toAmount,
  removeLiqValue,
  lpToken,
  handleDragore,
  onClickSelectTokenA,
  removeAllowance,
  onClickSelectTokenB,
  onClickApproveLiquidity,
  onClickRemoveLiquidity,
  onClickClose = () => {},
}) {
  let fromPrice = 0
  if (toPrice > 0) {
    fromPrice = _toFixed(fromExponential(1 / toPrice), 6)
  } else {
    fromPrice = _toFixed(0, 4)
  }

  const dragoreArr = [25, 50, 75, 100]
  const disableRemoveBtn = (tokenB.name === 'Select Token' || removeLiqValue ? false : true) || !removeAllowance
  return (
    <SwapSSbox01 className={'py-25 px-20 hover:border-highlight-shadow-primary'}>
      <SwapTitle01>
        {/* <CloseBtn onClick={onClickClose} /> */}
        <i className='fas helpIco fa-question-circle' data-tip='Remove your liquidity on the selected DEX'></i>
        <span>Remove Liquidity</span>
      </SwapTitle01>

      <RLTitle01>
        Amount <a href='#A'>Detailed</a>
      </RLTitle01>
      <RLTitle01 className='v2'>{removeLiqValue}%</RLTitle01>

      <DragorInput>
        <InputRange
          maxValue={100}
          minValue={0}
          value={removeLiqValue}
          formatLabel={(value) => `${value}%`}
          onChange={(value) => handleDragore(value)}
          disabled={tokenB.name === 'Select Token' || +balanceA === 0 ? true : false}
        />
      </DragorInput>

      <RLBTNBX data-type='light' data-html='true' data-class='data-tooltip' data-tip={tokenB.name === 'Select Token' || tokenA.name === 'Select Token' ? 'Please select tokens' : ''}>
        {dragoreArr.map((val, idx) => {
          return (
            <button key={idx} value={val} onClick={() => handleDragore(val)} disabled={tokenB.name === 'Select Token' ? true : false}>
              {val}%
            </button>
          )
        })}
      </RLBTNBX>
      <ArrowDownIconWrapper className='mt-12 mb-12'>
        <AiOutlineArrowDown size={20} />
      </ArrowDownIconWrapper>
      <YLMBX>
        <YLTitle02 className='v2'>
          {fromAmount === '' ? 0.0 : fromAmount}
          {/* <span> <TokenIcon className='token-icon' src={props.fromImg } alt="" /> {tokenA.name}</span> */}
          <SSbx02>
            <button className={tokenA.name !== 'Select Token' ? '' : 'OrangeBTN'} onClick={onClickSelectTokenA}>
              {tokenA.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={tokenA.image} /> : <></>}
              {tokenA.name} 
              {/* <i className='fas fa-chevron-down'></i> */}
            </button>
          </SSbx02>
        </YLTitle02>
        <YLTitle02 className='v2'>
          {toAmount === '' ? 0.0 : toAmount}
          {/* <span> <TokenIcon className='token-icon' src={props.toImg == '' ? YLIcon02 : props.toImg} alt="" /> {props.tokenTo}</span> */}
          <SSbx02>
            <button className={tokenB.name !== 'Select Token' ? '' : 'OrangeBTN'} onClick={onClickSelectTokenB}>
              {tokenB.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={tokenB.image} /> : <></>}
              {tokenB.name} 
              {/* <i className='fas fa-chevron-down'></i> */}
            </button>
          </SSbx02>
        </YLTitle02>
      </YLMBX>
      <YLTitle02 className='v3'>
        Price
        {![tokenB.name, tokenA.name].includes('Select Token') ? (
          <span>
            1 {tokenA.name} = {toPrice} {tokenB.name} <br />1 {tokenB.name} = {fromPrice} {tokenA.name}
          </span>
        ) : (
          <></>
        )}
      </YLTitle02>
      {![tokenB.name, tokenA.name].includes('Select Token') ? (
        <YLTitle02 className='v3'>
          {tokenA.name}/{tokenB.name} LP Token Pair Balance
          {lpToken !== null ? <span>{lpToken}</span> : null}
        </YLTitle02>
      ) : (
        <></>
      )}

      <YLBtnBar>
        <button className={removeAllowance || tokenB.name === 'Select Token' ? 'ylBTN02' : 'ylBTN03'} onClick={onClickApproveLiquidity} disabled={tokenB.name === 'Select Token' || removeAllowance}>
          Approve{' '}
        </button>
        <button
          className={!tokenA.name || !tokenB.name || !removeAllowance ? 'ylBTN02' : 'ylBTN03'}
          onClick={(e) => {
            if (!disableRemoveBtn) onClickRemoveLiquidity(e)
          }}
          // data-type='warning'
          data-event='click'
          data-tip={disableRemoveBtn ? 'Please Approve or Select first' : ''}
        >
          Remove
        </button>
      </YLBtnBar>
      <AppToolTip />
    </SwapSSbox01>
  )
}

export default RemoveLiquidity

const fontSizes = {
  xs: '10px',
  sm: '12px',
  md: '15px',
  '2md': '18px',
  lg: '20px',
  xl: '25px',
  '2xl': '30px',
  '3xl': '35px',
  '4xl': '40px',
  '5xl': '50px',
}

const globalProperties = {
  dark: {
    // text colors
    colorWhite: '#fff',
    // background colors
    headbg01: '#303239',
    headbg02: '#25282f',
    bodybg01: '#2c2f36',
    bodybg02: '#000000',
    bodybg03: '#16191e',
    bodybg04: '#090a0d',
    bodybg05: '#292c32',
    bodybg06: '#2a2a2a',
    bodybg07: '#4d4d4d',
    bodybg08: '#2d3037',
    bodybg09: '#34383f',
    bodybg010: '#020202',
    bodybg011: '#212429',
    bodybg012: '#303239',
    bodybg013: '#5d6268',
    bodybg014: 'rgb(22, 25, 30)',
    bodybg015: '#1f2227',
    colorGreen: '#8be05a',
    colorGreen01: '#a0ef72',
    colorGreen02: '#a0ef72b5',
    colorLightGrey: '#8e9195',
    colorGreen2: '#8db610',
    colorGrey: '#ababab',
    greyBorder: '#545861',
    grayBorder2: '#353840',
    grayBorder3: '#5a5e67',
    placeHolderColor: '#393d46',
    colorRed: '#c32b2d',
    colorGrey2: '#dddddd',
    colorOffWhite: '#faecc5',
    colorPlus: '#565a69',
    colorDarkGrey: '#5d6168',
    colorDark01: '#1d2027',
    modalOverlay: 'rgb(22 25 30 / 95%)',
    modalOverlay2: 'rgb(22 25 30 / 0%)',
    hrColor: '#3c3f46',
    colorGreenHover: '#67bf35',
    colorRedHover: '#9b1c1e',
    boxShadowYellow: 'rgb(254 187 0 / 53%)',
    trackColor: '#1f2127',
    thumbColor: '#6a6d74',
    colorGrey3: '#9a9a9a',
    colorGrey4: '#282b31',
    colorGrey5: '#aaaaaa',
    colorGrey6: '#878787',
    colorGrey7: '#3f4249',
    colorGrey8: '#0e0f13',
    boxShadowOne: 'rgb(0 0 0 / 10%)',
    colorBlue01: '#1f21dd',
    colorBlue02: '#28a4e4',
    networkIconBg: '#303a4c',
    pageBg: '#1f2229',
    selectedTokenBg: '#25282f',
    textColor: 'white',
    // font size
    fontSize: fontSizes,
  },
  white: {
    // text colors
    colorWhite: '#fff',
    // background colors
    headbg01: '#303239',
    headbg02: '#25282f',
    bodybg01: '#2c2f36',
    bodybg02: '#000000',
    bodybg03: '#16191e',
    bodybg04: '#090a0d',
    bodybg05: '#292c32',
    bodybg06: '#2a2a2a',
    bodybg07: '#4d4d4d',
    bodybg08: '#2d3037',
    bodybg09: '#34383f',
    bodybg010: '#020202',
    bodybg011: '#212429',
    bodybg012: '#303239',
    bodybg013: '#5d6268',
    bodybg014: 'rgb(22, 25, 30)',
    bodybg015: '#1f2227',
    colorGreen: '#8be05a',
    colorGreen01: '#a0ef72',
    colorGreen02: '#a0ef72b5',
    colorLightGrey: '#8e9195',
    colorGreen2: '#8db610',
    greyBorder: '#545861',
    grayBorder2: '#353840',
    grayBorder3: '#5a5e67',
    placeHolderColor: '#393d46',
    colorRed: '#c32b2d',
    colorOffWhite: '#faecc5',
    colorPlus: '#565a69',
    colorDarkGrey: '#5d6168',
    colorDark01: '#1d2027',
    modalOverlay: 'rgb(22 25 30 / 95%)',
    modalOverlay2: 'rgb(22 25 30 / 0%)',
    hrColor: '#3c3f46',
    colorGreenHover: '#67bf35',
    colorRedHover: '#9b1c1e',
    boxShadowYellow: 'rgb(254 187 0 / 53%)',
    trackColor: '#f0f0f0',
    thumbColor: '#b9bdc5',
    colorGrey: '#ababab',
    colorGrey2: '#dddddd',
    colorGrey3: '#9a9a9a',
    colorGrey4: '#282b31',
    colorGrey5: '#aaaaaa',
    colorGrey6: '#878787',
    colorGrey7: '#3f4249',
    colorGrey8: '#0e0f13',
    boxShadowOne: 'rgb(0 0 0 / 10%)',
    colorBlue01: '#1f21dd',
    colorBlue02: '#28a4e4',
    networkIconBg: '#303a4c',
    pageBg: '#1f2229',
    selectedTokenBg: '#25282f',
    textColor: 'white',
    // font size
    fontSize: fontSizes,
  },
}

export const theme = (isDarkTheme) => {
  return isDarkTheme ? globalProperties.dark : globalProperties.white
}

/**TestNet */
// export const MAIN_ZERO_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
// export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

// export const WETH = '0xc778417E063141139Fce010982780140Aa0cD5Ab';
// export const WBNB = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd';
// export const ETH_CONTRACT_ADDRESS = '0x3F6c883FE9ad3D211F5efbeec4e97Ec4DB02B9D8';
// export const BNB_CONTRACT_ADDRESS = '0x313B808E0399B97a7CcA1367a79aC52b43Ca5aA3';
// export const FACTORY_CONTRACT_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
// export const LICENSEE = '0x0000000000000000000000000000000000000000';

// // page 1
// export const UNISWAP_FACTORY = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
// export const UNISWAP_ROUTER = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
// // page 2
// export const SUSHISWAP_ROUTER = '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506';
// export const SUSHISWAP_FACTORY = '0xc35DADB65012eC5796536bD9864eD8773aBc74C4';
// //page 3
// export const PANCAKE_ROUTER = '0xD99D1c33F9fC3444f8101754aBC46c52416550D1';
// export const PANCAKE_FACTORY = '0x6725F303b657a9451d8BA641348b6761A6CC7a17';
// //page 4
// export const BSWAP_ROUTER = '0xd954551853F55deb4Ae31407c423e67B1621424A';
// export const BSWAP_FACTORY = '0xCe8fd65646F2a2a897755A1188C04aCe94D2B8D0';
// //page 6
// export const DEFI_ROUTER = '0xCeB90E4C17d626BE0fACd78b79c9c87d7ca181b3';
// export const DEFI_FACTORY = '0x9DEB29c9a4c7A88a3C0257393b7f3335338D9A9D';
// //page 7
// export const SHIBASWAP_ROUTER = '0x4B0d61C160572CAcC0A20D2dbeF6E0138bf58681';
// export const SHIBASWAP_FACTORY = '0x1DFA4F89acD5cc33A100AD1347970bA9E91737Cb';
// //page 8
// export const WAULTSWAP_ROUTER = '0xD48745E39BbED146eEC15b79cBF964884F9877c2';
// export const WAULTSWAP_FACTORY = '0xB42E3FE71b7E0673335b3331B3e1053BD9822570';
// //page 9
// export const MDEX_ROUTER = '0x7DAe51BD3E3376B8c7c4900E9107f12Be3AF1bA8';
// export const MDEX_FACTORY = '0x3CD1C46068dAEa5Ebb0d3f55F6915B10648062B8';

// // page 5 not check
// // page 6,8,9 mainnet only

// export const SUSHISWAP_BSC_ROUTER = '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506';
// export const SUSHISWAP_BSC_FACTORY = '0xc35dadb65012ec5796536bd9864ed8773abc74c4';

/**MainNet */

export const MAIN_ZERO_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const WETH = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
export const WBNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
export const WMATIC = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
export const ETH_CONTRACT_ADDRESS = '0x3F6c883FE9ad3D211F5efbeec4e97Ec4DB02B9D8' //
export const BNB_CONTRACT_ADDRESS = '0x3F6c883FE9ad3D211F5efbeec4e97Ec4DB02B9D8' // same value with ETH
export const POLYGON_CONTRACT_ADDRESS = '0x3F6c883FE9ad3D211F5efbeec4e97Ec4DB02B9D8' // TODO need to find the vaule
export const FACTORY_CONTRACT_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
export const LICENSEE = '0x0000000000000000000000000000000000000000'

// page 1
export const UNISWAP_FACTORY = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
export const UNISWAP_ROUTER = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'
// page 2
export const SUSHISWAP_ROUTER = '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F'
export const SUSHISWAP_FACTORY = '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac'
// page 2 (bsc)
export const SUSHISWAP_BSC_ROUTER = '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506'
export const SUSHISWAP_BSC_FACTORY = '0xc35dadb65012ec5796536bd9864ed8773abc74c4'
//page 3
export const PANCAKE_ROUTER = '0x10ED43C718714eb63d5aA57B78B54704E256024E'
export const PANCAKE_FACTORY = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
//page 4
export const BSWAP_ROUTER = '0xd954551853F55deb4Ae31407c423e67B1621424A'
export const BSWAP_FACTORY = '0xCe8fd65646F2a2a897755A1188C04aCe94D2B8D0'
//page 6
export const DEFI_ROUTER = '0xCeB90E4C17d626BE0fACd78b79c9c87d7ca181b3'
export const DEFI_FACTORY = '0x9DEB29c9a4c7A88a3C0257393b7f3335338D9A9D'
//page 7
export const SHIBASWAP_ROUTER = '0x03f7724180AA6b939894B5Ca4314783B0b36b329'
export const SHIBASWAP_FACTORY = '0x115934131916C8b277DD010Ee02de363c09d037c'
// page 8
export const WAULTSWAP_ROUTER = '0xD48745E39BbED146eEC15b79cBF964884F9877c2'
export const WAULTSWAP_FACTORY = '0xB42E3FE71b7E0673335b3331B3e1053BD9822570'
// page 9
export const MDEX_ROUTER = '0x7DAe51BD3E3376B8c7c4900E9107f12Be3AF1bA8'
export const MDEX_FACTORY = '0x3CD1C46068dAEa5Ebb0d3f55F6915B10648062B8'

// page 5 not check
// page 6,8,9 mainnet only

// DMC pool address in BSC
export const DMC_POOL_ADDRESS = '0x17d389be3778c18757e02b79c39bad54fd02643a'
export const DMC_CONTRACT_ADDRESS = '0x9b707e2a6a917b1b26de80bf17b33cc88e6cb1db'

import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import FlipNumbers from 'react-flip-numbers'
import Media from '../../Theme/media-breackpoint'
import { io } from 'socket.io-client'
import { REACT_APP_TVL_SOCKET_SERVER_URL } from 'constants'

const socketUrl = REACT_APP_TVL_SOCKET_SERVER_URL
let socket = io.connect(socketUrl, {
  transports: ['websocket'],
})

const toCurr = (num) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(num)
}

const Tvl = () => {
  const [tvl, setTvl] = useState(0)
  const [reference, setReference] = useState(null)

  const tvlChangeListener = useCallback((value) => {
    setTvl(value)
  }, [])

  useEffect(() => {
    if (tvl && reference) {
      const inValid = '$,.'
      const nodes = reference?.children[0]?.children || []
      for (let i = 0; i < nodes.length; i++) {
        const text = nodes[i]?.innerText
        if (nodes[i] && inValid.includes(text)) {
          nodes[i].style.borderColor = 'transparent'
        }
      }
    }
  }, [tvl, reference])

  useEffect(() => {
    socket.on('tvl:changed', tvlChangeListener)

    return () => {
      socket.off('tvl:changed', tvlChangeListener)
    }
  }, [tvlChangeListener])

  return (
    <>
      {
        <MainBox>
          <Title01 className=''>Total Locked Value of multiple DEXs</Title01>
          <TimerBox ref={setReference}>
            {/* <AnimatedNumber value={toCurr(tvl)} frameStyle={() => ({
            textIndent: '6px',
            fontSize: '30px',
            background: 'rgb(22, 25, 30)',
        })} /> */}

            <FlipNumbers
              numberStyle={{
                textIndent: '-5px',
                background: 'rgb(22, 25, 30)',
              }}
              nonNumberStyle={{ fontSize: '30px' }}
              height={90}
              width={70}
              numbers={toCurr(tvl)}
              play
            />
            {/* <span>$</span>
                       {tvl.length >0 && <span>{tvl.charAt(tvl.length - (tvl.length-0))}</span>}
                        {tvl.length >1 && <span>{tvl.charAt(tvl.length - (tvl.length-1))}</span>},
                        {tvl.length >2 && <span>{tvl.charAt(tvl.length - (tvl.length-2))}</span>}
                        {tvl.length >3 && <span>{tvl.charAt(tvl.length - (tvl.length-3))}</span>}
                        {tvl.length >4 && <span>{tvl.charAt(tvl.length - (tvl.length-4))}</span> },
                        {tvl.length >5 && <span>{tvl.charAt(tvl.length - (tvl.length-5))}</span>}
                        {tvl.length >6 && <span>{tvl.charAt(tvl.length - (tvl.length-6))}</span>}
                        {tvl.length >7 && <span>{tvl.charAt(tvl.length - (tvl.length-7))}</span>},
                        {tvl.length >8 && <span>{tvl.charAt(tvl.length - (tvl.length-8))}</span>}
                        {tvl.length >9 && <span>{tvl.charAt(tvl.length - (tvl.length-9))}</span>}
                        {tvl.length >10 && <span>{tvl.charAt(tvl.length - (tvl.length-10))}</span>} */}
            {/* . */}
            {/* <span>0</span>
                        <span>0</span> */}
          </TimerBox>
        </MainBox>
      }
    </>
  )
}

export default Tvl

const MainBox = styled.div`
  width: fit-content;
  margin: 0 auto;
`

const TimerBox = styled.div`
//   font:400 50px/60px 'Press Start 2P', arial;
    font-weight: 700;
   color:#8de05c; margin: 0 0 15px 0;
  display:flex; align-items: center; justify-content:space-around;
    section{
        align-items: end !important;
    }
  & > section{
      & > span{ 
          :nth-child(1){
              background: rgb(22, 25, 30);
            //   text-indent: 6px;
              height: 90px;
              width: 70px;
              display: flex;
              font-size: 45px !important;
              border: rgb(90, 94, 103) solid 1.5px !important;
              justify-content: center;
              align-items: center;
              ${Media.xs} {
                font-size:10px !important;
              }
          }
      }
  }

  span{
      font-size:40px !important;
      margin-right: 5px;
      border-radius: 10px;
      border: rgb(90, 94, 103) solid 1.5px;
      ${Media.xs} {
        font-size:10px !important;
        width:18px !important;
        height:21px !important;
        line-height: 21px !important;
        border-radius: 4px;
      }

`
const Title01 = styled.div`
  font-weight: 800;
  font-size: 18px;
  color: ${({ theme }) => theme.colorGrey8};
  text-shadow: 0px 1px ${({ theme }) => theme.colorGrey7};
  margin: 0 0 15px 0;
`

import BNB_ICON from './../Assets/images/bnb-icon.png'
import UNI_ICON from './../Assets/images/unicornIco.png'
import ETH_ICON from './../Assets/images/eth-icon.png'
import TENEX_ICON from './../Assets/images/tenex.png'
import TREE_ICON from './../Assets/images/tree.svg'
import DAI_ICON from './../Assets/images/dai.jpg'
import POLYGON_ICON from './../Assets/images/matic.webp'
import { BNB_CONTRACT_ADDRESS, ETH_CONTRACT_ADDRESS, WBNB, WETH, WMATIC, ZERO_ADDRESS } from './addresses'
export * from './env'

// export const localprovide = 'http://localhost:3000'
// export const defiPlus_Ether_Url = 'http://localhost:3000/api/getTvl';
export const defiPlus_Ether_Url = 'https://api.coingecko.com/api/v3/coins/'
export const LARGE_APPROVAL_AMOUNT = 999

export const CHAINS = {
  Mainnet: '0x1',
  Kovan: '0x42',
  Ropsten: '0x3',
  Rinkeby: '0x4',
  Goerli: '0x5',
  BSCTESTNET: '0x61',
  BSCMAINNET: '0x38',
  FANTOMTESTNET: '0xfa2',
  FANTOMMAINNET: '0xfa',
  POLYGONMAINNET: '0x89',
}

export const NETWORK_ICONS = {
  1: ETH_ICON,
  56: BNB_ICON,
  137: POLYGON_ICON,
}

export const NETWORK_NAMES = {
  eth: 'eth',
  bsc: 'bsc',
  polygon: 'polygon',
  ftm: 'ftm',
}

export const URL_ARRAY = {
  [NETWORK_NAMES.eth]: [
    {
      enabled: true,
      locked: true,
      default: true,
      data: 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://tokens.1inch.eth.link',
    },
    {
      home: 'https://tokenlists.org/token-list?url=https://www.gemini.com/uniswap/manifest.json',
      data: 'https://www.gemini.com/uniswap/manifest.json',
      enabled: false,
    },
    {
      home: 'https://tokenlists.org/token-list?url=https://tokens.coingecko.com/uniswap/all.json',
      data: 'https://tokens.coingecko.com/uniswap/all.json',
      enabled: false,
    },
    //   {
    //     home: 'https://tokenlists.org/token-list?url=defi.cmc.eth',
    //     data: 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://defi.cmc.eth.link',
    //   enabled: false,
    // },
    {
      home: 'https://tokenlists.org/token-list?url=https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
      data: 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
      enabled: false,
    },
  ],
  [NETWORK_NAMES.bsc]: [
    {
      enabled: true,
      default: true,
      home: 'https://wispy-bird-88a7.uniswap.workers.dev/?url=https://tokens.pancakeswap.finance/pancakeswap-extended.json',
      data: 'https://wispy-bird-88a7.uniswap.workers.dev/?url=https://tokens.pancakeswap.finance/pancakeswap-extended.json',
    },
  ],
  [NETWORK_NAMES.polygon]: [
    {
      enabled: true,
      default: true,
      home: 'https://api-polygon-tokens.polygon.technology/tokenlists/allTokens.tokenlist.json',
      data: 'https://api-polygon-tokens.polygon.technology/tokenlists/allTokens.tokenlist.json',
    },
  ],
}

export const DEFAULT_ITEM = { enabled: true, locked: true, data: 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://tokens.1inch.eth.link' }
export const ETH_BASES = ['ETH', 'WETH', 'USDT', 'vETH']
export const BSC_BASES = ['BNB', 'WBNB', 'USDT', 'BTCB']
export const POLY_BASES = ['MATIC', 'WBNB', 'USDT', 'BTCB']
export const FTM_BASES = ['FTM', 'anyFTM', 'USDT', 'vETH']
export const BASE_TOKENS = {
  [NETWORK_NAMES.bsc]: [
    { value: '0x0000000000000000000000000000000000000000', label: 'BNB', symbol: 'BNB', image: BNB_ICON },
    { value: '0xFDf7e61131C77A9ae56D3c9Ec1472101AB228155', label: 'TNX20POOL', symbol: 'TNX20POOL', image: TENEX_ICON },
    { value: '0x40A9B1D856d8B9d4410118e349e153A22419650d', label: 'Prince', symbol: 'Prince', image: TREE_ICON },
    { value: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867', label: 'DAI', symbol: 'DAI', image: DAI_ICON },
  ],
  [NETWORK_NAMES.ftm]: [
    { value: '0x0000000000000000000000000000000000000000', label: 'BNB', symbol: 'BNB', image: BNB_ICON },
    { value: '0xFDf7e61131C77A9ae56D3c9Ec1472101AB228155', label: 'TNX20POOL', symbol: 'TNX20POOL', image: TENEX_ICON },
    { value: '0x40A9B1D856d8B9d4410118e349e153A22419650d', label: 'Prince', symbol: 'Prince', image: TREE_ICON },
    { value: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867', label: 'DAI', symbol: 'DAI', image: DAI_ICON },
  ],
  [NETWORK_NAMES.polygon]: [
    { value: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0', label: 'MATIC', symbol: 'MATIC', image: BNB_ICON },
    // { value: '0xFDf7e61131C77A9ae56D3c9Ec1472101AB228155', label: 'TNX20POOL', image: TENEX_ICON },
    // { value: '0x40A9B1D856d8B9d4410118e349e153A22419650d', label: 'Prince', image: TREE_ICON },
    // { value: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867', label: 'DAI', image: DAI_ICON },
  ],
  [NETWORK_NAMES.eth]: [
    { value: '0x0000000000000000000000000000000000000000', label: 'ETH', symbol: 'ETH', image: ETH_ICON },
    { value: '0xc2118d4d90b274016cB7a54c03EF52E6c537D957', label: 'DAI', symbol: 'DAI', image: DAI_ICON },
    { value: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', label: 'UNI', symbol: 'UNI', image: UNI_ICON },
    //  { value: '0xc778417e063141139fce010982780140aa0cd5ab', label: 'WETH', image: ETH_ICON, dai: 0xc2118d4d90b274016cB7a54c03EF52E6c537D957 }
  ],
}

export const commonBasesTokens = {
  [NETWORK_NAMES.eth]: ['ETH', 'USDT', 'vETH'],
  [NETWORK_NAMES.bsc]: ['BNB', 'USDT', 'BTCB'],
  [NETWORK_NAMES.polygon]: ['MATIC', 'USDT', 'BTCB'],
  [NETWORK_NAMES.ftm]: ['FTM', 'anyFTM', 'USDT', 'vETH'],
}

export const CHAINID_BY_NAME = {
  [NETWORK_NAMES.eth]: 1,
  [NETWORK_NAMES.bsc]: 56,
  [NETWORK_NAMES.polygon]: 137,
}
export const CHAIN_NAME_BY_ID = {
  1: NETWORK_NAMES.eth,
  56: NETWORK_NAMES.bsc,
  137: NETWORK_NAMES.polygon,
}

export const explorerUrl = {
  [NETWORK_NAMES.eth]: 'https://etherscan.io/tx/',
  [NETWORK_NAMES.bsc]: 'https://bscscan.com/tx/',
  [NETWORK_NAMES.polygon]: 'https://polygonscan.com/tx/',
}
export const CHAINS_HEX = {
  [NETWORK_NAMES.eth]: '0x1',
  [NETWORK_NAMES.bsc]: '0x38',
  [NETWORK_NAMES.polygon]: '0x89',
}
export const INFRA_PROJECT_ID = '35b7dab555974b4ba417a516a7a11d27'

export const HTTP_PROVIDERS = {
  [NETWORK_NAMES.eth]: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
  [NETWORK_NAMES.bsc]: 'https://bsc-dataseed.binance.org',
  [NETWORK_NAMES.polygon]: 'https://polygon-rpc.com',
}

export const NETWORKS = [
  {
    W_TOKEN_ADDRESS: WETH,
    CHAIN: 1,
    MAIN_TOKEN_ICON: ETH_ICON,
    MAIN_TOKEN_NAME: 'ETH',
    HTTP_PROVIDER_URL: HTTP_PROVIDERS[NETWORK_NAMES.eth],
    IAgentAddress: ETH_CONTRACT_ADDRESS,
  },
  {
    W_TOKEN_ADDRESS: WBNB,
    CHAIN: 56,
    MAIN_TOKEN_ICON: BNB_ICON,
    MAIN_TOKEN_NAME: 'BNB',
    HTTP_PROVIDER_URL: HTTP_PROVIDERS[NETWORK_NAMES.bsc],
    IAgentAddress: BNB_CONTRACT_ADDRESS,
  },
  {
    W_TOKEN_ADDRESS: WMATIC,
    CHAIN: 137,
    MAIN_TOKEN_ICON: POLYGON_ICON,
    MAIN_TOKEN_NAME: 'MATIC',
    HTTP_PROVIDER_URL: HTTP_PROVIDERS[NETWORK_NAMES.polygon],
    IAgentAddress: 'TODO',
  },
]

export const SUPPORTED_CHAIN_IDS = [1, 3, 4, 5, 42, 56, 97, 137]
// export const SUPPORTED_CHAIN_IDS = [1, 3, 4, 5, 42, 56, 137]
export const DEFAULT_TIMEOUT = 1000 * 60 * 60 // 1 hr
export const DEFAULT_SLIPPAGE_VALUE = 0.1 // 0.1%

/// https://www.dextools.io/app/fantom/pair-explorer/0xf0702249f4d3a25cd3ded7859a165693685ab577
export const DEX_TOOLS_NETWORK_NAMES = {
  [NETWORK_NAMES.eth]: 'ether',
  [NETWORK_NAMES.bsc]: 'bsc',
  [NETWORK_NAMES.ftm]: 'fantom',
  [NETWORK_NAMES.polygon]: 'polygon',
}

export const defaultToken = {
  image: '',
  name: 'Select Token',
  address: '',
}

export const LOAD_MORE_COUNT = 7
